<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <MySidebar active="myprofile" :user="body.userName" :image="body.image" />
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="left-profile mt-5 pt-4">
          <div class="header-profile">
            <h2> {{ $t("Modify the mobile number") }}</h2>
          </div>
          <div class="col-lg-6 col-md-12 from-all">
            <Card>
              <template #content>
                <form @submit.prevent="save()">
                  <div class="text-center mb-5">
                    <img
                      class="from-img"
                      v-if="body.image"
                      :src="$baseUploadURL + body.image"
                    />
                    <img
                      class="from-img"
                      v-else
                      src="/images/user.png"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputname" class="form-label d-block">{{
                      $t("Current Password")
                    }}</label>
                    <!-- <input
                      type="name"
                      v-model="body.password"
                      :class="{ 'is-invalid': isInvalid && !body.password }"
                      class="form-control"
                      id="exampleInputname"
                      placeholder="........."
                    /> -->
                    <Password v-model="value3" toggleMask placeholder="........."></Password>

                    <div class="mb-3">
                      <label for="exampleInputname" class="form-label d-block">{{
                        $t("New Password")
                      }}</label>
                      <!-- <input
                        type="name"
                        v-model="body.newPassword"
                        :class="{
                          'is-invalid': isInvalid && !body.newPassword,
                        }"
                        class="form-control"
                        id="exampleInputname"
                        placeholder="........."
                      /> -->
                    <Password v-model="value3" toggleMask placeholder="........."></Password>

                      <div id="nameHelp" class="form-text"></div>
                    </div>
                  </div>
                  <div class="">
                    <label for="exampleInputname" class="form-label d-block">{{
                      $t("Confirm the new password")
                    }}</label>
                    <!-- <input
                      type="name"
                      v-model="body.confirmPassword"
                      :class="{
                        'is-invalid': isInvalid && !body.confirmPassword,
                      }"
                      class="form-control"
                      id="exampleInputname"
                      placeholder="........."
                    /> -->
                    <Password v-model="value3" toggleMask placeholder="........."></Password>

                    <div id="nameHelp" class="form-text"></div>
                  </div>

                  <div class=" pt-3">
                    <button class="pass1">    {{ $t("forget your password?") }}</button>
                    <button
                      type="submit"
                      :disabled="disabledAdd"
                      class="btn-editprofile btn btn-outline-success btn-block"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </form>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        image: null,
        password: null,
        newPassword: null,
        confirmPassword: null,
      },
      isInvalid: false,
      disabledAdd: false,
      id: null,
    };
  },

  methods: {
    save() {
      if (
        this.body.password &&
        this.body.newPassword &&
        this.body.confirmPassword &&
        this.body.newPassword == this.body.confirmPassword &&
        this.disabledAdd == false
      ) {
        this.disabledAdd = true;

        delete this.body.image;

        this.$http
          .post("users/updatePassword", {
            oldPassword: this.body.password,
            newPassword: this.body.newPassword,
            confirmPassword: this.body.confirmPassword,
          })
          .then(
            (res) => {
              this.body.image = res.data.image;

              this.disabledAdd = false;
              this.$router.push("myprofile");
              this.$toast.add({
                severity: "success",
                summary: this.$t("Done Successfully"),
                detail: this.$t("Updated Successfully"),
                life: 3000,
              });
            },
            (err) => {
              this.disabledAdd = false;

              this.$toast.add({
                severity: "error",
                summary: this.$t("Error"),
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
      } else {
        this.isInvalid = true;
        if (this.body.newPassword != this.body.confirmPassword) {
          this.$toast.add({
            severity: "error",
            summary: this.$t("Error"),
            detail: this.$t("password not equal confirmation password"),
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("Error"),
            detail: this.$t("Please add the required fields"),
            life: 3000,
          });
        }
      }
    },
  },
  async created() {
    this.$http.post("users/getUserById", {}).then((res) => {
      const body = res.data;
      this.body.userName = body.userName;
      this.body.phone = body.phone;
      this.body.image = body.image;
      this.id = body._id;
    });
  },
};
</script>
<style>

</style>
